import { defineStore } from "pinia";
import { ref } from "vue";
import nuxtStorage from "nuxt-storage";
import { useURLs } from "~~/constants/api.urls";
import { CLD_ENV } from "~~/constants/env";

import { useUnAutorize } from "~/composables/unAuthHandler";
export const useCartStore = defineStore("cart", () => {
  const pending = ref(false);
  const cartItems = ref([]);
  const qtyCart = ref(0);
  const cartData = ref({ items: [] });
  const priceSingleQty = ref([]);
  const sessionData = ref({});
  const orderPlaceResponse = ref({});
  const showCartBtn = ref(false);

  const addToCart = async ({ sku, qty }) => {
    let alreadyAdded;
    pending.value = true;
    if (cartData.value?.items && cartData.value.items.length) {
      alreadyAdded = cartData.value.items.find((x) => x.ArticleId === sku);
    }
    let added;
    if (!alreadyAdded) {
      added = await addToCartHttp({
        sku: sku,
        qty,
      });
      pending.value = false;
      if (added.value) {
        const exist = added.value.items.find((x) => x.ArticleId === sku);
        if (exist) {
          const sumQty = added.value.items
            .map((item) => +item.Quantity)
            .reduce((prev, next) => prev + next);

          qtyCart.value = sumQty;
          cartData.value = added.value;
          EventBus.emit("refresh-cart");
          return { status: true, added: true };
        } else {
          return { status: false, added: false };
        }
      } else {
        return { status: false, added: false };
      }
    } else {
      pending.value = true;
      let qtySum = qty + alreadyAdded.Quantity;
      await UpdateProductQty({ qty: qtySum, dlId: alreadyAdded.DlId });
      pending.value = false;
      EventBus.emit("refresh-cart");
      return { status: true, added: false };
    }
  };
  const getFromCart = async () => {
    const data = await getCartHttp();

    if (data && data.value && data.value.data) {
      cartData.value = data.value.data;
      if (data.value.data.items.length) {
        priceSingleQty.value = data.value.data.items.map(
          ({ Quantity, Total }) => parseFloat(Total / Quantity).toFixed(2)
        );
        const sumQty = data.value.data.items
          .map((item) => +item.Quantity)
          .reduce((prev, next) => prev + next);
        qtyCart.value = sumQty;
      }
    }
  };
  const UpdateProductQty = async ({ qty, dlId }) => {
    const data = await UpdateQtyInCartHttp({ qty, dlId });
    // console.log("updated qty in cart", data.data);
    cartData.value = data.value.data;
    const sumQty = data.value.data.items
      .map((item) => +item.Quantity)
      .reduce((prev, next) => prev + next);
    qtyCart.value = sumQty;
  };
  const removeLineCart = async ({ dlId }) => {
    const data = await removeFromCartHttp({ dlId });
    cartData.value = data.value.data;
    const sumQty = data.value.data.items.length
      ? data.value.data.items
          .map((item) => +item.Quantity)
          .reduce((prev, next) => prev + next)
      : 0;

    qtyCart.value = sumQty;
  };
  const placeOrder = async ({ refrence }) => {
    const data = await placeOrderHttp({ refrence });
    orderPlaceResponse.value = data.value.data;
    if (orderPlaceResponse.value.Status) {
      cartData.value = { items: [] };
      qtyCart.value = 0;
    }
    console.log("data CL Order place", data);
  };

  const resetCart = async () => {
    const data = await resetUserCartHttp();
    if (data.value && data.value.data) {
      cartData.value = { items: [] };
      qtyCart.value = 0;
    }
  };

  const showAddCartBtn = (value) => {
    showCartBtn.value = value;
  };
  const clearCartResponse = () => {
    orderPlaceResponse.value = {};
  };
  //#region http functions
  const addToCartHttp = async ({ sku, qty }) => {
  
    const token = nuxtStorage.localStorage.getData("token");
    const payload = {
      sku: sku,
      qty: +qty,
    };
    const url = useURLs[CLD_ENV].CLD_ADD_TO_CART;
    const { data } = await useFetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${token}`,
      },
      onResponseError({ response }) {
        if (response.status === 403) {
          useUnAutorize();
        }
      },
    });

   
    return data;
  };

  const UpdateQtyInCartHttp = async ({ qty, dlId }) => {
    const token = nuxtStorage.localStorage.getData("token");
    const url = `${useURLs[CLD_ENV].UPDATE_QTY_IN_CART}?qty=${qty}&dlId=${dlId}`;
    const { data } = await useFetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${token}`,
      },
      onResponseError({ response }) {
        if (response.status === 403) {
          useUnAutorize();
        }
      },
    });
    return data;
  };

  const getCartHttp = async () => {
    const url = useURLs[CLD_ENV].GET_CART_ITEMS;
    const token = nuxtStorage.localStorage.getData("token");
    if (!token) {
      console.log("user is not authenticated");
      return;
    }
    const { data } = await useFetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      onResponseError({ response }) {
        if (response.status === 403) {
          useUnAutorize();
        }
      },
    });
    return data;
  };

  const removeFromCartHttp = async ({ dlId }) => {
    const url = `${useURLs[CLD_ENV].REMOVE_FROM_CART}?dlId=${dlId}`;
    const token = nuxtStorage.localStorage.getData("token");

    const { data } = await useFetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${token}`,
      },
      onResponseError({ response }) {
        if (response.status === 403) {
          useUnAutorize();
        }
      },
    });
    return data;
  };

  const resetUserCartHttp = async () => {
    const url = useURLs[CLD_ENV].RESET_CART;
    const token = nuxtStorage.localStorage.getData("token");
    const { data } = await useFetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${token}`,
      },
      onResponseError({ response }) {
        if (response.status === 403) {
          useUnAutorize();
        }
      },
    });
    return data;
  };

  const placeOrderHttp = async ({ refrence }) => {
    const url = useURLs[CLD_ENV].CAR_CLIENT_PLACE_ORDER;
    const token = nuxtStorage.localStorage.getData("token");
    const { data } = await useFetch(`${url}?refrence=${refrence}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${token}`,
      },
      onResponseError({ response }) {
        if (response.status === 403) {
          useUnAutorize();
        }
      },
    });

    return data;
  };
  const syncCartQty = (qty) => {
    qtyCart.value = qty;
  };
  //#endregion
  return {
    cartItems,
    priceSingleQty,
    sessionData,
    addToCart,
    showCartBtn,
    showAddCartBtn,
    getFromCart,
    cartData,
    UpdateProductQty,
    removeLineCart,
    placeOrder,
    orderPlaceResponse,
    resetCart,
    qtyCart,
    clearCartResponse,
    syncCartQty,
  };
});
